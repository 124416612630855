<template>
  <div class="row">
    <div class="col-12 wrapper-box-fiche" v-if="data.prix_spain">   

        <div class="box-white-fiche" v-if="data.prix_spain['orphan_drug'] || data.prix_spain['registration_number'] || data.prix_spain['marketing_authorization_date'] || data.prix_spain['status']">
            <h3 class="text-orange">{{$t('Regulatory information')}}</h3>
            <table class="comparateur2 border-top-gray">
                <tr>
                    <td class="bold"><strong> {{$t('Orphan drug')}} </strong></td>
                    <td> {{ data.prix_spain['orphan_drug'] ? data.prix_spain['orphan_drug'] : '-'}}</td>
                </tr>
                <tr>
                    <td class="bold"><strong> {{$t('Registration number')}} </strong></td>
                    <td> {{ data.prix_spain['registration_number'] ? data.prix_spain['registration_number'] : '-' }} </td>
                </tr>
                <tr>
                    <td class="bold"><strong>Marketing authorization date</strong></td>
                    <td> {{data.prix_spain['marketing_authorization_date'] ? DDMMMYY(data.prix_spain['marketing_authorization_date']) : '-'}} </td>
                </tr>
                <tr>
                    <td class="bold"><strong>Marketed</strong></td>
                    <td> {{data.prix_spain['status'] ? data.prix_spain['status'] : '-' }} </td>
                </tr>
            </table>
        </div>

        <div class="box-white-fiche" v-if="data.prix_spain.prix_spain_mssi.length > 0 || data.prix_spain.prix_spain_bot_plus.length > 0">
            <h3  class="text-orange">Pricing data</h3>
            <table class="comparateur2 border-top-gray" v-if="data.prix_spain.prix_spain_mssi.length > 0">
                <thead>
                    <tr>
                        <th class="gray" width="12%"><strong> Manufacturer Selling Price (exc. VAT, €) </strong></th>
                        <th class="gray" width="12%"><strong> Pharmacy selling price (exc. VAT, €) </strong></th>
                        <th class="gray" width="12%"><strong> Public price (inc. VAT, €) </strong></th>
                        <th class="gray" width="12%"><strong> Reference price € NHS </strong></th>
                        <th class="gray" width="12%"><strong> Lower price of homogenous medical group € NHS </strong></th>
                        <th class="gray" width="12%"><strong> Marketing date </strong></th>
                        <th class="gray" width="12%"><strong> Removal date </strong></th>
                        <th class="gray" width="12%"><strong> Update date </strong></th>
                    </tr>
                </thead>
                <tr v-for="(value, key) in data.prix_spain.prix_spain_mssi" :key="'prix_spain_mssi_'+key">
                    <td> {{ value['selling_price'] ? format_french_number(value['selling_price']) : '-'}}</td>
                    <td> {{ value['price_vat'] ? format_french_number(value['price_vat']) : '-' }} </td>
                    <td> {{ value['public_price'] ? format_french_number(value['public_price']) : '-'}} </td>
                    <td> {{ value['reference_price'] ? value['reference_price'] : '-' }} </td>
                    <td> {{ value['lower_price_homogenous_medical'] ? value['lower_price_homogenous_medical'] : '-'}}</td>
                    <template v-if="key === 0">
                        <td :rowspan="data.prix_spain.prix_spain_mssi.length"> {{ data.prix_spain['nomenclator_registration_date'] ? DDMMMYY(data.prix_spain['nomenclator_registration_date']) : '-' }} </td>
                        <td :rowspan="data.prix_spain.prix_spain_mssi.length"> {{ data.prix_spain['date_nomenclator'] ? DDMMMYY(data.prix_spain['date_nomenclator']) : '-'}} </td>
                    </template>
                    <td> {{ value['date']? value['date'] : '-' }} </td>
                </tr>
            </table>

            <table class="comparateur2 border-top-gray" v-if="data.prix_spain.prix_spain_bot_plus.length > 0">
                <thead>
                    <tr>
                        <th class="gray" width="17%">Manufacturer selling price (exc. VAT, €)</th>
                        <th class="gray" width="17%">Hospital selling price (exc. VAT, €)</th>
                        <th class="gray" width="17%">Hospital selling price (inc. VAT, €)</th>
                        <th class="gray" width="17%">Marketing date</th>
                        <th class="gray" width="17%">Removal date</th>
                        <th class="gray" width="15%">Update date</th>
                    </tr>
                </thead>
                <tr v-for="(value, key) in data.prix_spain.prix_spain_bot_plus" :key="key">
                    <td> {{ value['price_laboratoy_reference'] ? value['price_laboratoy_reference'] : '-'}}</td>
                    <td> {{ value['price_laboratoy'] ? value['price_laboratoy'] : '-' }} </td>
                    <td> {{ value['price_vat'] ? value['price_vat']: '-'}} </td>
                    <template v-if="key === 0">
                        <td :rowspan="data.prix_spain.prix_spain_mssi.length"> {{ data.prix_spain['nomenclator_registration_date'] ? DDMMMYY(data.prix_spain['nomenclator_registration_date']) : '-' }} </td>
                        <td :rowspan="data.prix_spain.prix_spain_mssi.length"> {{ data.prix_spain['date_nomenclator'] ? DDMMMYY(data.prix_spain['date_nomenclator']) : '-'}} </td>
                    </template>
                    <td> {{ value['date'] ? value['date'] : '-' }} </td>
                </tr>
            </table>
        </div>

        <div class="box-white-fiche" v-if="data.prix_spain['contribution_benefit'] || data.prix_spain['medical_group_code'] || data.prix_spain['medical_group_name'] || 
                                            data.prix_spain['hospital_diagnosis'] || data.prix_spain['long_term_treatment'] || data.prix_spain['special_medical_monitoring']">
            <h3 class="text-orange">{{ $t('information generale') }}</h3>
            <table class="comparateur2 border-top-gray">
                <tr>
                    <td class="bold"><strong> {{$t('Contribution of the benefit')}} </strong></td>
                    <td> {{ data.prix_spain['contribution_benefit'] ? data.prix_spain['contribution_benefit'] : '-'}}</td>
                </tr>
                <tr>
                    <td class="bold"><strong> {{$t('Medical group code')}} </strong></td>
                    <td> {{ data.prix_spain['medical_group_code'] ? data.prix_spain['medical_group_code'] : '-' }} </td>
                </tr>
                <tr>
                    <td class="bold"><strong>Medical group name</strong></td>
                    <td> {{data.prix_spain['medical_group_name'] ? data.prix_spain['medical_group_name'] : '-'}} </td>
                </tr>
                <tr>
                    <td class="bold"><strong>Hospital diagnosis</strong></td>
                    <td> {{data.prix_spain['hospital_diagnosis'] ? data.prix_spain['hospital_diagnosis'] : '-' }} </td>
                </tr>
                <tr>
                    <td class="bold"><strong>Long term treatment</strong></td>
                    <td> {{data.prix_spain['long_term_treatment'] ? data.prix_spain['long_term_treatment'] : '-' }} </td>
                </tr>
                <tr>
                    <td class="bold"><strong>Special medical monitoring</strong></td>
                    <td> {{data.prix_spain['special_medical_monitoring'] ? data.prix_spain['special_medical_monitoring'] : '-' }} </td>
                </tr>
            </table>
        </div>


    </div> 
</div>
</template>

<script>
import { format_french_number, DDMMMYY } from '../../../utils';  

export default {
    name : 'PrixSpain',
    computed : {
        data() {
            return this.$store.getters['detail/data']
        },
    },
    methods : {
        format_french_number,
        DDMMMYY
    }
}
</script>

<style>

</style>